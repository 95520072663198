<!--
 * @Author: wangwei
 * @Date: 2020-12-29 16:05:36
 * @LastEditTime: 2021-01-08 14:11:44
 * @LastEditors: Please set LastEditors
 * @Description: 我的余额
 * @FilePath: /java-pc/src/views/member/Header.vue
-->
<template>
  <div class="sld_my_book_balance">
    <MemberTitle memberTitle="票折余额"></MemberTitle>
    <div class="top_info flex_row_center_center">
      <div class="top_info_box">
        <div><span>可用余额：</span><span class="price">{{Number(balance_info).toFixed(2)}}</span></div>
      </div>
    </div>
    <div class="nav_list flex_row_start_center">
      <div :class="{item:true, active:current_type=='balance',pointer:true}">{{L['余额明细']}}
      </div>
    </div>
    <!-- 余额明细列表 -->
    <div v-if="current_type=='balance'" class="list_container">
      <div class="top_title flex_row_center_center">
        <div class="time flex_row_center_center">{{L['交易日期']}}</div>
        <div class="num flex_row_center_center">{{L['收入/支出']}}(元)</div>
        <div class="reason">{{L['变动原因']}}</div>
      </div>
      <div class="list_item flex_row_center_center" v-for="(balanceItem,index) in balance_list.data" :key="index">
        <div class="time flex_row_center_center">{{balanceItem.createTime}}</div>
        <div :class="{num:true,green:true, flex_row_center_center:true,red:balanceItem.state==10}">
          {{balanceItem.state==0?'+':'-'}}{{balanceItem.price}}</div>
        <div class="reason">{{balanceItem.content}} 订单号：{{balanceItem.orderSn}}</div>
      </div>
      <SldCommonEmpty tip="暂无余额明细~" totalHeight="587" totalWidth="1003" v-show="!balance_list.data.length" />
    </div>
    <!-- 充值明细 -->
    <!-- <div v-if="current_type=='recharge'" class="list_container">
      <div class="top_title flex_row_center_center">
        <div class="time flex_row_center_center">{{L['交易日期']}}</div>
        <div class="num flex_row_center_center">{{L['充值金额']}}(元)</div>
        <div class="reason">{{L['状态']}}</div>
      </div>
      <div class="list_item flex_row_center_center" v-for="(rechargeItem,index) in recharge_list.data" :key="index">
        <div class="time flex_row_center_center">{{rechargeItem.createTime}}</div>
        <div class="num flex_row_center_center">{{rechargeItem.payAmount}}</div>
        <div class="reason">{{rechargeItem.payStateValue}}</div>
      </div>
      <SldCommonEmpty tip="暂无充值明细~" totalHeight="587" totalWidth="1003" v-show="!recharge_list.data.length" />
    </div> -->

    <!-- 分页 start -->
    <div class="flex_row_end_center sld_pagination">
      <el-pagination @current-change="handleCurrentChange" :currentPage="pageData.current"
        :page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
        :hide-on-single-page="true">
      </el-pagination>
    </div>
    <!-- 分页 end -->
  </div>
</template>

<script>
  import { useRouter } from "vue-router";
  import { ElMessage, ElPagination } from "element-plus";
  import { getCurrentInstance, onMounted, reactive, ref } from "vue";
  import MemberTitle from '../../components/MemberTitle';
  import SldCommonEmpty from '../../components/SldCommonEmpty'
  export default {
    name: "Balance",
    components: {
      MemberTitle,
      SldCommonEmpty,
      ElPagination
    },
    setup() {
      const router = useRouter()
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const pageData = reactive({
        current: 1,
        pageSize: 20,
        total: 0
      });
      const balance_list = reactive({ data: [] })
      const recharge_list = reactive({ data: [] })
      const balance_info = ref('');
      const current_type = ref('balance')//当前列表显示的数据类型
      const getBalanceList = () => {//获取余额明细列表
        // var param = {};
        // param.state = 0;
        // param.current = pageData.current;
        // param.pageSize = pageData.pageSize;
        proxy
          .$post("v3/business/front/orderOperate/getDisBalanceLog")
          .then(res => {
            if (res.state == 200) {
              balance_list.data = res.data;
              // balance_info.data.rechargeSum = new Number(balance_info.data.rechargeSum).toFixed(2)
              // balance_info.data.balanceAvailable = new Number(balance_info.data.balanceAvailable).toFixed(2)
              balance_list.data.map(item => item.price = new Number(item.price).toFixed(2))
              // pageData.total = res.data.pagination.total;
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      const getBalanceinfo = () => {//获取余额信息
        proxy
          .$post("v3/business/front/orderOperate/getDisBalance")
          .then(res => {
            if (res.state == 200) {
              balance_info.value = res.data

            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      const initList = () => {
        if (current_type.value == 'recharge') {
          getRechargeList()
        } else {
          getBalanceList();
        }
      }
      //向前翻页
      const handlePrevCilickChange = () => {
        pageData.current--;
        initList();
      };
      //向后翻页
      const handleNextCilickChange = () => {
        pageData.current++;
        initList();
      };
      //页数改变
      const handleCurrentChange = current => {
        pageData.current = current;
        initList();
      };
      //去充值
      const toRecharge = () => {
        router.push({
          path: '/member/recharge'
        })
      }
      onMounted(() => {
        getBalanceList();
        getBalanceinfo()
      });
      return {
        L,
        pageData,
        handlePrevCilickChange,
        handleNextCilickChange,
        handleCurrentChange,
        balance_list,
        current_type,
        recharge_list,
        getBalanceinfo,
        balance_info,
        toRecharge
      };
    }
  };
</script>

<style lang="scss" scoped>
  @import "../../style/bookBalance.scss";

  .sld_pagination {
    margin-top: 20px;
    margin-bottom: 20px;
  }
</style>